import React from "react"
import { Link } from "gatsby"
import Navbar from "../components/navbar"


const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath
  let header

  if (isRootPath) {
    header = (
      <h1 className="main-heading">
        <Link to="/">{title}</Link>
      </h1>
    )
  } else {
    header = (
      <Link className="header-link-home" to="/">
        {title}
      </Link>
    )
  }

  return (
    <div className="global-wrapper" data-is-root-path={isRootPath}>
      <section className="hero is-fullheight-with-navbar 	has-background-danger">
        <Navbar />
        <div className="hero-head"></div>

        <div className="hero-body p-0 m-0">
          {children}
        </div>

        <div className="hero-foot"></div>

      </section>
      <footer className='footer center has-background-danger'></footer>
    </div>
  )
}

export default Layout
