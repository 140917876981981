import React from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"


export default class Navbar extends React.Component {

  constructor(props) {
    super(props);
    // this.handleLoad = this.handleLoad.bind(this);  
  }

  state = {
    isActive: 0,
  }

  handleClick = event =>  {
    // $("myclass") //  $ is available here
    let status = this.state.isActive === 1 ? 0 : 1
    this.setState({
      isActive: status
    })
  }

  render() { 

    return (
      <div className="hero-head">
        <nav className={this.state.isActive ? "navbar is-spaced " : "navbar is-spaced"}>

            <div className="navbar-brand">

                <div className="hero-body">
                  <Link className="header-link-home" to="/">
                    <h2 className="title is-2">Becky Worley</h2>
                  </Link>
                </div>

              <span role="button" 
                    className={this.state.isActive ? "navbar-burger is-active" : "navbar-burger"}
                    data-target="navbar-menu"
                    onClick={this.handleClick}>
                <span></span>
                <span></span>
                <span></span>
              </span>
            </div>


              <div id="navbar-menu" className={this.state.isActive ? "navbar-menu is-active" : "navbar-menu"} onClick={this.handleClick}>
                <div className="navbar-end is-pulled-right">
                  <div className=" navbar-item">
                    <Link
                      className="button is-primary is-outlined header-link-portfolio "
                      to="/portfolio"
                    >
                      Portfolio
                    </Link>
                  </div>

                  <div className="navbar-item ">
                    <Link className="button is-primary is-outlined header-link-me" to="/me">
                      About Me
                    </Link>
                  </div>
                </div>
              </div>

        </nav>
      </div>
    )
  }
}